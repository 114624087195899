<template>
  <div class="page">
    <div class="view">
      <div class="viewT">
        <div style="font-weight: bold;">宠物信息</div>
        <el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
      </div>
      <div class="ViewBox">
        <div class="viewC">
          <div class="viewnickName">基本信息</div>
          <div class="comView">
            <div class="comItem" style="align-items: initial;height: auto;">
              <div class="comItemName">宠物图片:</div>
              <el-upload ref="fileUpload" :http-request="getUploadImg" :multiple="false" list-type="picture-card"
                :auto-upload="true" :show-file-list="false" action="" class="cropper-upload-box">
                <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
                  <img v-if="imgUrl1" :src="imgUrl1" class="avatar">
                </div>
              </el-upload>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>宠物名称:</div>
              <el-input v-model="nickName" placeholder="请输入宠物名称" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">芯片号:</div>
              <el-input type="number" v-model="epromNo" placeholder="请输入芯片号" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>售价:</div>
              <el-input type="number" v-model.number="salePrice" placeholder="请输入售价" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">进价:</div>
              <el-input type="number" v-model.number="purchasePrice" placeholder="请输入进价"
                style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>性别:</div>
              <el-radio-group v-model="petSex">
                <el-radio :label="1">弟弟</el-radio>
                <el-radio :label="2">妹妹</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>宠物品种:</div>
              <el-select v-model="firstClass" placeholder="请选择一级分类" style="width: 200px;" clearable
                @change="handleFirstClassChange"> 
                <el-option v-for="item in firstClassList.itemList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="secondClass" placeholder="请选择二级分类" style="width: 200px;margin-left: 12px;" clearable
                v-if="firstClass != ''">
                <el-option v-for="item in secondClassList.itemList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>出生日期:</div>
              <el-date-picker v-model="bronDate" @change="changeDate" type="date" :clearable="false" placeholder="选择日期"
                style="width:180px;"
                :picker-options="{ disabledDate(time) { return new Date().getTime() < time.getTime() } }">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>到店日期:</div>
              <el-date-picker v-model="inShopDays" @change="changeDateRange" type="date" :clearable="false"
                placeholder="选择日期" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">数量:</div>
              <el-input-number :disabled="editMsg != null" v-model="addPetCount" :step="1" style="margin-left: 30px;"
                size="small"></el-input-number>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName"><span>*</span>详情:</div>
              <el-input v-model="remark" type="textarea" rows="3"></el-input>
            </div>
          </div>
        </div>
        <div class="viewC">
          <div class="viewnickName">更多信息</div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">来源:</div>
              <el-input type="text" v-model="source" placeholder="请输入来源" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">身高:cm</div>
              <el-input type="" v-model="height" placeholder="请输入身高" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">体重:kg</div>
              <el-input type="" v-model="weight" placeholder="请输入体重" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">颜色:</div>
              <el-input type="text" v-model="petColor" placeholder="请输入颜色" style="width: 200px;"></el-input>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">体内驱虫日期:</div>
              <el-date-picker v-model="inBodyDeworming" @change="changeDateRange1" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">体外驱虫日期:</div>
              <el-date-picker v-model="outBodyDeworming" @change="changeDateRange2" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">第一针疫苗:</div>
              <el-date-picker v-model="firstVaccines" @change="changeDateRange3" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">第二针疫苗:</div>
              <el-date-picker v-model="secondVaccines" @change="changeDateRange4" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">第三针疫苗:</div>
              <el-date-picker v-model="thirdVaccines" @change="changeDateRange5" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
          <div class="comView">
            <div class="comItem">
              <div class="comItemName">狂犬疫苗:</div>
              <el-date-picker v-model="rabiesVaccines" @change="changeDateRange6" type="date" :clearable="false"
                placeholder="选择时间" style="width:180px;">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="viewB">
        <!-- <el-button style="width: 110px;" v-if="editMsg != null">打印条码</el-button> -->
        <el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { put } from '@/api/upload.js'
export default {
  data() {
    return {
      bronDate: '',
      inShopDays: '',
      editMsg: JSON.parse(this.$route.query.editMsg),  //为null是新增  编辑跳转信息
      nickName: '', //商品名称
      epromNo: '', //芯片号
      cover: '',  //商品图片
      imgUrl1: '', //图片
      salePrice: '',//售价
      purchasePrice: '',//进价
      petSex: null,
      remark: '',//备注
      petColor: '',//颜色
      source: '',//来源
      height: '',
      weight: '',
      addPetCount: 1,
      inBodyDeworming: '',//体内驱虫
      outBodyDeworming: '',//体外驱虫
      firstVaccines: '',//第一针疫苗
      secondVaccines: '',//第二针疫苗
      thirdVaccines: '',//第三针疫苗
      rabiesVaccines: '',//狂犬
      firstClass: '', //一级分类
      firstClassList: {},  //一级分类列表
      secondClass: '',  //二级分类
      secondClassList: {},  //二级分类列表
    }
  },
  mounted() {
    this.getProductData()
    if (this.editMsg != null) {
      this.firstClassList = this.editMsg.firstCategoryInfo;
      this.firstClass = this.editMsg.firstCategoryInfo.id;
      this.secondClass = this.editMsg.secondCategoryInfo.id;
      this.secondClassList = this.editMsg.secondCategoryInfo;
      this.purchasePrice = this.editMsg.purchasePrice;
      this.salePrice = this.editMsg.salePrice;
      this.nickName = this.editMsg.nickName;
      this.epromNo = this.editMsg.epromNo;
      this.petSex = Number(this.editMsg.petSex)
      this.remark = this.editMsg.remark
      this.petColor = this.editMsg.petColor
      this.source = this.editMsg.source
      this.height = Number(this.editMsg.height)
      this.weight = Number(this.editMsg.weight)
      this.inBodyDeworming = this.$public.FTime(this.editMsg.inBodyDeworming)
      this.outBodyDeworming = this.$public.FTime(this.editMsg.outBodyDeworming)
      this.firstVaccines = this.$public.FTime(this.editMsg.firstVaccines)
      this.inShopDays = this.$public.FTime(this.editMsg.inShopDays)
      this.secondVaccines = this.$public.FTime(this.editMsg.secondVaccines)
      this.thirdVaccines = this.$public.FTime(this.editMsg.thirdVaccines)
      this.rabiesVaccines = this.$public.FTime(this.editMsg.rabiesVaccines)
      this.cover =this.editMsg.cover? this.editMsg.cover+'?'+ "x-oss-process=image/resize,w_300/quality,q_90":'';
      this.imgUrl1 =this.editMsg.cover? this.editMsg.cover+'?'+ "x-oss-process=image/resize,w_300/quality,q_90":'';
      this.bronDate = this.$public.FTime(this.editMsg.bronDate);
      this.addPetCount = Number(this.editMsg.addPetCount)
    }
  },
  methods: {
    changeDate(val) {
      this.bronDate = val
    },
    changeDateRange(val) {
      this.inShopDays = val
    },
    changeDateRange1(val) {
      this.inBodyDeworming = val
    },
    changeDateRange2(val) {
      this.outBodyDeworming = val
    },
    changeDateRange3(val) {
      this.firstVaccines = val
    },
    changeDateRange4(val) {
      this.secondVaccines = val
    },
    changeDateRange5(val) {
      this.thirdVaccines = val
    },
    changeDateRange6(val) {
      this.rabiesVaccines = val
    },
    //提交
    handleSubmitClick() {
      if (this.salePrice == '') {
        this.$message({
          type: 'error',
          message: '请输入售价'
        });
        return
      }
      if (this.nickName == '') {
        this.$message({
          type: 'error',
          message: '请输入商品名称'
        });
        return
      }
      if (this.petSex == '') {
        this.$message({
          type: 'error',
          message: '请选择性别'
        });
        return
      }
      if (this.firstClass == '') {
        this.$message({
          type: 'error',
          message: '请选择一级分类'
        });
        return
      }
      if (this.secondClass == '') {
        this.$message({
          type: 'error',
          message: '请选择二级分类'
        });
        return
      }

      let params = {
        cover: this.cover,
        salePrice: this.salePrice,
        purchasePrice: this.purchasePrice,
        nickName: this.nickName,
        bronDate:this.bronDate? Date.parse(this.bronDate) / 1000+'':'',
        epromNo: this.epromNo,
        petSex: Number(this.petSex),
        remark: this.remark,
        petColor: this.petColor,
        source: this.source,
        height: Number(this.height),
        weight: Number(this.weight),
        inShopDays:this.inShopDays? Date.parse(this.inShopDays) / 1000+'':'',
        inBodyDeworming:this.inBodyDeworming? Date.parse(this.inBodyDeworming) / 1000+'':'',
        outBodyDeworming:this.outBodyDeworming? Date.parse(this.outBodyDeworming) / 1000+'':'',
        firstVaccines:this.firstVaccines? Date.parse(this.firstVaccines) / 1000+'':'',
        secondVaccines:this.secondVaccines? Date.parse(this.secondVaccines) / 1000+'':'',
        thirdVaccines: this.thirdVaccines?Date.parse(this.thirdVaccines) / 1000+'':'',
        rabiesVaccines:this.rabiesVaccines? Date.parse(this.rabiesVaccines) / 1000+'':'',
      }
      //获取1级类目参数
      this.firstClassList.itemList.map((item) => {
        if (item.id == this.firstClass) {
          params.firstCategoryInfo = {
            id: item.id,
            name: item.name,
            code: item.code
          }
        }
      })
      //获取2级类目参数
      this.secondClassList.itemList.map((item) => {
        if (item.id == this.secondClass) {
          params.secondCategoryInfo = {
            id: item.id,
            name: item.name,
            code: item.code
          }
        }
      })
      if (this.editMsg != null) {   //编辑
        params.petId = this.editMsg.petId;
        this.$http.post("/product/pet/update", params).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '已提交'
            });
            sessionStorage.setItem('mark', 1)
            this.$router.go(-1);
          }
        })
      } else {
        params.addPetCount = Number(this.addPetCount)
        this.$http.post("/product/pet/add", params).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '已提交'
            });
            sessionStorage.setItem('mark', 1)
            this.$router.go(-1);
          }
        })
      }

    },
    //1级类目变化
    handleFirstClassChange() {
      this.secondClass = '';
      this.secondClassList = [];
      this.firstClassList.itemList.map((item) => {
        if (item.id == this.firstClass) {
          this.secondClassList = item
        }
      })
    },
    //获取类目
    getProductData() {
      let params = {
        code: 'pettype'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          this.firstClassList = res.data;
          if (this.firstClass != '') {
            this.firstClassList.itemList.map((item) => {
              if (item.id == this.firstClass) {
                this.secondClassList = item
              }
            })
          }
        }
      })
    },
    //返回
    handleBackClick() {
      this.$confirm('是否返回上一页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.go(-1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    getUploadImg(item) {
      // 随机命名
      var fileName = item.file.name
      var file = item.file // 
      put(fileName, file).then(result => {  // 调oss api 上传图片
        this.cover = result.url;
        this.imgUrl1 = result.url;
        // this.fileList.push(result.name)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  color: $fontColor;

  .view {
    background: #fff;
    height: 100%;

    border-radius: 20px;
    display: flex;
    flex-direction: column;

    .viewT {
      box-sizing: border-box;
      height: 68px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #F2F2F2;
      padding: 0 20px;
      justify-content: space-between;
    }

    .ViewBox {
      display: flex;
      flex: 1;
      overflow-y: scroll;

      .viewC {
        width: 50%;
        padding: 0 20px;
        padding-top: 20px;

        .viewnickName {
          width: 64px;
          padding-left: 9px;
          font-size: 16px;
          font-weight: bold;
          background: url(../../../assets/img/cangku/detailName.png) no-repeat;
          background-position: left top;
          background-size: 16px 16px;
          margin-bottom: 20px;
        }

        .comView {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 14px;

          .comItem {
            display: flex;
            align-items: center;
            height: 40px;

            .comItemName {
              width: 100px;
              text-align: right;
              margin-right: 8px;

              span {
                color: #FF3939;
              }
            }
          }

          .comTxt {
            color: #666;
            margin-left: 12px;

            span {
              color: #F77E04;
              cursor: pointer;
            }
          }
        }
      }
    }

    .viewB {
      height: 68px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      border-top: solid 1px #F2F2F2;
    }

    .viewC::-webkit-scrollbar {
      display: none;
    }
  }

  .upload-cropper-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cropper-upload-box {
    display: flex;

    .el-upload {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
        max-height: 90%;
      }
    }
  }
}
</style>
